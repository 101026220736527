const { CLASS_IS_ACTIVE } = await import("@/util/layout");
const { default: Swiper, Pagination, Autoplay } = await import("swiper");

Swiper.use([Pagination, Autoplay]);

const CLASS_HEADER_SLIDER = "header-slider";
const CLASS_ITEM = CLASS_HEADER_SLIDER + "__item";
const CLASS_ITEMS = CLASS_HEADER_SLIDER + "__items";
const CLASS_PAGINATION = CLASS_HEADER_SLIDER + "__pagination";
const CLASS_BULLET = CLASS_HEADER_SLIDER + "__bullet";

export class HeaderSlider {
    private static instance: HeaderSlider;

    public static getInstance(): HeaderSlider {
        if (!HeaderSlider.instance) {
            HeaderSlider.instance = new HeaderSlider();
        }

        return HeaderSlider.instance;
    }

    private constructor() {
        const sliders = document.getElementsByClassName(CLASS_HEADER_SLIDER);

        for (let index = 0; index < sliders.length; index++) {
            const slider = sliders.item(index) as HTMLElement | null;

            if (slider) {
                const autoplayStatus = !!Number.parseInt(slider.dataset.autoplayStatus ?? "0");
                const autoplayDuration = Number.parseInt(slider.dataset.autoplayDuration ?? "3000");

                const paginationEl = slider.getElementsByClassName(CLASS_PAGINATION).item(0) as HTMLElement | null;

                const swiper = new Swiper(slider, {
                    init: false,
                    slidesPerView: 1,
                    wrapperClass: CLASS_ITEMS,
                    slideClass: CLASS_ITEM,
                    autoplay: autoplayStatus
                        ? {
                            delay: autoplayDuration,
                        }
                        : false,

                    freeMode: false,

                    pagination: {
                        el: paginationEl,
                        bulletClass: CLASS_BULLET,
                        bulletActiveClass: CLASS_IS_ACTIVE,
                        clickable: true,
                    },
                });

                swiper.init();
            }
        }
    }
}
